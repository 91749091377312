.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.buttons-container button {
  padding: 10px 20px;
  background-color: #20A39E;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons-container button:hover {
  background-color: #20A39E;
}
