.
.about-us-container {
  margin: 0 auto;
  max-width: 79%;
}
.about-us-content {
  white-space: pre-wrap;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}