.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 1rem;
  max-width: 400px;
  margin: 0 auto;
  background-image: url('https://example.com/gift-pattern.jpg'); /* Replace with your preferred gift pattern image URL */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.formLabel {
  margin-bottom: 0.5rem;
  color: #333; /* Change text color to white for better contrast on a bright background */
}

.formControl {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); 
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.5rem; 
  font-size: 1rem;
}
.submitButton {
  background-color: #20A39E; 
  color: white; 
  padding: 0.5rem 1rem; 
  font-size: 1rem; 
  border: none; 
  cursor: pointer; 
  border-radius: 4px; 
  margin-top: 1rem; 
  transition: background-color 0.2s ease; 
}
.submitButton:hover {
  background-color: #20A39E;
}