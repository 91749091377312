.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20A39E;
  color: white;
  position: relative;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
}

.brand {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.brand-logo {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}

.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 20px;
  width: 30px;
}

.menu-line {
  background-color: white;
  height: 3px;
  width: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 1rem;
  background-color: #333;
  padding: 1rem;
  border-radius: 0 0 0 1rem;
}

.menu-item {
  color: inherit;
  text-decoration: none; 
  cursor: pointer;
  padding: 0.5rem 0;
}

.menu-item:hover {
  background-color: #555;
}
