/* Variables for easy customization */
:root {
  --primary-font: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --code-font: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --primary-color: #333;
  --bg-color: #f5f5f5;
  --accent-color: #007bff;
  --transition-speed: 0.3s;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 1.6;
  color: var(--primary-color);
  background-color: var(--bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color var(--transition-speed);
}

a:hover,
a:focus {
  color: darken(var(--accent-color), 10%);
}

code {
  font-family: var(--code-font);
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 4px;
  border-radius: 3px;
}

pre {
  font-family: var(--code-font);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1em;
  border-radius: 3px;
  overflow-x: auto;
}
